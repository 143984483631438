'use strict'

angular.module('cb.picklist-service', [])
    .factory('PickListService', ["ApiService", function (ApiService) {

        var pickListService = {
            getPickListByPickListName: function (picklistName) {
                try {
                    var data = { PickListName: picklistName };
                    var url = '/webapi/api/v1/picklist/getPickListByPickListName';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            getPaginatedPickListByPickListName: function (data) {
                try {
                    var url = '/webapi/api/v1/picklist/getPaginatedPickListByPickListName';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },

            getPicklists: function (data) {
                try {
                    var url = '/webapi/api/v1/picklist/getPicklists';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            createPicklist: function (data) {
                try {
                    var url = '/webapi/api/v1/picklist/createPicklist';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            updatePicklist: function (data) {
                try {
                    var url = '/webapi/api/v1/picklist/updatePicklist';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
			deletePicklist: function (id) {
				try {
					var url = '/webapi/api/v1/picklist/deletePiclist/' + id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			unDeletePicklist: function (id) {
				try {
					var url = '/webapi/api/v1/picklist/unDeletePiclist/' + id;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			deletePicklistItem: function (id) {
                try {
                    var url = '/webapi/api/v1/picklist/deletePiclistItem/' + id;
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            getPicklistNames: function () {
                try {
                    var url = '/webapi/api/v1/picklist/getPicklistNames';
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);
                }
            }
        };

        return pickListService;
    }]);
